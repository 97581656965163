<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          
        </div>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id" width="100"></el-table-column>
      <el-table-column prop="contribution" label="贡献值"></el-table-column>
      <el-table-column prop="userNum" label="用户人数"></el-table-column>
      <el-table-column prop="giveNum" label="赠送TALK数"></el-table-column>
      <el-table-column label="中奖人数">
        <template slot-scope="scope">
          <p v-for="(item,index) in scope.row.reward" :key="index">{{ item.num }}</p>
        </template>
      </el-table-column>
      <el-table-column label="奖品类型">
        <template slot-scope="scope">
          <p v-for="(item,index) in scope.row.reward" :key="index">
            <span v-for="(i,index2) in item.rewardList" :key="index2">{{ i.rewardName }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="奖品数量">
        <template slot-scope="scope">
          <p v-for="(item,index) in scope.row.reward" :key="index">
            <span v-for="(i,index2) in item.rewardList" :key="index2">{{ i.amount }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="img" label="中奖等级图标">
        <template slot-scope="scope">
          <p v-for="(item,index) in scope.row.reward" :key="index">
            <img :width="40" :src="item.icon" />
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="img" label="中奖图标">
        <template slot-scope="scope">
          <p v-for="(item,index) in scope.row.reward" :key="index">
            <span v-for="(i,index2) in item.rewardList" :key="index2">
              <img :width="40" :src="i.icon" />
            </span>
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="up" label="状态">
        <template slot-scope="scope">
          <span class="success" v-if="scope.row.up">上架</span>
          <span class="warning" v-else>下架</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.up?'下架':'上架'}}</el-button
          >
          <!-- <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}宝箱`" :visible.sync="addVisible" width="600px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="ruleFormBox"
      >
        <el-form-item label="贡献值" prop="contribution" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.contribution"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户人数" prop="userNum" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.userNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="赠送TALK数" prop="giveNum" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.giveNum"
          ></el-input>
        </el-form-item>

        <div class="priceBox" v-for="(item,index) in ruleForm.reward" :key="index">
          <!-- <p class="del" @click="delRewardFunc(index)" v-if="ruleForm.reward.length>1"><i class="el-icon-delete"></i> 删除</p> -->
          <el-form-item label="中奖等级图标"  :prop="'reward.' + index +'.icon'" :rules="rules.typeIcon">
            <uploadImg @change="(val)=>uploadImgChange2(val,index)" :imageFile="[{url:item.icon}]"></uploadImg>
          </el-form-item>
          <el-form-item label="中奖人数" :prop="'reward.' + index + '.num'" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="item.num"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否发送通知" :prop="'reward.' + index +'.broadcast'" :rules="rules.changeRule">
            <el-radio-group v-model="item.broadcast">
              <el-radio :label="false">否</el-radio>
              <el-radio :label="true">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="subChild" v-for="(i,index2) in item.rewardList" :key="index2">
            <el-form-item label="奖品类型" :prop="'reward.' + index +'.rewardList.' + index2 + '.rewardName'" :rules="rules.blurRule">
              <el-select
                v-model="i.rewardName"
                placeholder="类型"
              >
                <el-option
                  v-for="(item,key) in rewardTypeOptions"
                  :key="key"
                  :label="item"
                  :value="key">
                </el-option>
              </el-select>
            </el-form-item>
          
          <el-form-item label="奖品数量" :prop="'reward.' + index +'.rewardList.' + index2 + '.amount'" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="i.amount"
            ></el-input>
          </el-form-item>
          <el-form-item label="中奖图标"  :prop="'reward.' + index +'.rewardList.' + index2 + '.icon'" :rules="rules.typeIcon">
            <uploadImg @change="(val)=>uploadImgChange(val,index,index2)" :imageFile="[{url:i.icon}]"></uploadImg>
          </el-form-item>
        </div>
          <!-- <el-form-item label="">
            <el-button
              size="medium"
              type="warning"
              @click="addRewardFunc"
              >添加奖品</el-button
            >
            
          </el-form-item> -->
        </div>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      typeOptions:{
        '0':'语音房首页', 
        '1':'房间内ICON', 
      },
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      rewardTypeOptions:{
        'talk':'talk',
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    uploadImgChange(val,index,index2){
      if(val&&val.length>0) {
        this.ruleForm.reward[index].rewardList[index2].icon=val[0].url
      }
    },
    uploadImgChange2(val,index){
      if(val&&val.length>0) {
        this.ruleForm.reward[index].icon=val[0].url
      }
    },
    addRewardFunc(){
      this.ruleForm.reward.push({rewardName:'talk'})
    },
    delRewardFunc(index){
      this.ruleForm.reward.splice(index,1)
    },
    addFunc(){
      this.ruleForm = {reward:[{rewardList:[{rewardName:'talk'}]},{rewardList:[{rewardName:'talk'}]},{rewardList:[{rewardName:'talk'}]}]};
      this.addVisible = true;
    },
    toEdit(data) {
      //编辑
      this.ruleForm = JSON.parse(JSON.stringify(data))
      if(!this.ruleForm.reward){
        this.ruleForm.reward = [{rewardList:[{rewardName:'talk'}]},{rewardList:[{rewardName:'talk'}]},{rewardList:[{rewardName:'talk'}]}]
      } 
      
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = {...this.ruleForm}
          ruleForm.reward = ruleForm.reward.map((x,index)=>{
            x.id=index+1
            return x
          })
          let res = await this.$http.roomChestSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.roomChestList({...this.searchForm});
      if (res) {
        this.tableData = res.data;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.banneDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    unmountFunc(row) {
      this.$confirm(`确定要${row.up?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.roomChestSave({ ...row,  up:row.up?false:true})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.priceBox{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f0f2f5;
  padding: 10px 0;
  margin-bottom: 10px;
  position: relative;
  .del{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: #f56c6c;
    z-index: 10;
  }
  .subChild{
    background-color: #e6e7e7;
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
</style>